body {
    margin: 0;
    padding: 0;
    font-family: 'Source Code Pro', monospace;
}

a {
    color: inherit;
    text-decoration: none;
}

.hidden {
    display: none;
}