.mo-main {
    background: #000;
    color: #00ff00;
    &.mo-mood {
        color: #fff;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background: linear-gradient(-45deg, #0d0d0d, #3fa9f5, #93278f, #d4145a, #fbb03b, #8cc63f, #3fa9f5, #397aa8, #531094, #f037b8, #f0c968);
        background-size: 400% 400%;
        -webkit-animation: Gradient 200s ease infinite;
        -moz-animation: Gradient 200s ease infinite;
        animation: Gradient 200s ease infinite;
    }
    .mo-wrapper {
        max-width: 800px;
        margin: 0 auto;
        .mo-container {
            padding: 8rem 2rem 1rem 4rem;
            min-height: calc(100vh - 9rem);
        }
        .mo-header {
            margin-bottom: 3rem;
        }
    }
}

.mo-content {
    font-size: 1rem;
    a {
        text-decoration: none;
        color: inherit;
        &:hover {
            font-weight: bold;
            span {
                font-size: 20px;
                line-height: 28px;
            }
        }
    }
    p {
        margin: 0;
        line-height: 30px;
        &.prompt, &.start {
            a {
                cursor: pointer;
            }
            .blink {
                animation: blink 1.2s infinite;
                a:hover {
                    font-size: 20px;
                    line-height: 28px;
                    animation: none;
                }
            }
        }
    }
    .cursor {
        color: #fff;
    }
}
.mo-intro, .mo-menu {
    p:nth-child(3), .start {
        margin-top: 10px;
    }
    ol a:hover {
        text-decoration: underline;
    }
}

.page {
    p, ul {
        margin-left: 0;
    }
    p {
        margin-top: 10px;
    }
    .title {
        font-weight: bold;
        margin-left: 0;
    }
    .back-actions {
        p {
            line-height: 30px;
            margin-left: 0;
        }
        a {
            margin-right: 10px;
            &:hover {
                font-size: 17px;
                font-weight: bold;
                text-decoration: underline;
            }
        }
    }
}

@keyframes blink {
    to { opacity:  .0; }
}

@-webkit-keyframes Gradient {
    0% {
        background-position: 0 50%
    }
    50% {
        background-position: 100% 50%
    }
    100% {
        background-position: 0 50%
    }
}
@-moz-keyframes Gradient {
    0% {
        background-position: 0 50%
    }
    50% {
        background-position: 100% 50%
    }
    100% {
        background-position: 0 50%
    }
}
@keyframes Gradient {
    0% {
        background-position: 0 50%
    }
    50% {
        background-position: 100% 50%
    }
    100% {
        background-position: 0 50%
    }
}
